/* header and navbar */
header {
  background-color: #e4f1f3;
  border-bottom: 10px solid #7c3030;
}

.navbar {
  margin-bottom: 0;
  min-height: 70px;
}

.navbar-brand {
  padding: 10px;
}

.navbar-brand img {
  max-width: 170px;
  padding-top: 5px;
}

@media screen and (max-width: 1000px) {
  .navbar-brand img {
    max-width: 160px;
  }
}

@media screen and (max-width: 430px) {
  .navbar-brand img {
    max-width: 140px;
  }
  .navbar {
    min-height: 70px;
  }
}

.navbar-nav > li > a {
  padding-top: 20px;
  color: #7c3030;
  text-transform: uppercase !important;
}

.navbar-nav > li > a i {
  padding-right: 2px;
}

.nav > li > a:focus, .nav > li > a:hover {
  background-color: transparent;
}

.navbar-toggle {
  background-color: #7c3030;
  margin-right: 0;
}

.navbar-toggle .icon-bar {
  background-color: white;
}

/* body, main and basic styles */
body {
  color: #666;
  background-color: #e4f1f3;
  font: 16px "Lato", sans-serif;
  line-height: 23px;
  word-wrap: break-word;
  font-weight: 300;
}

a {
  color: #7c3030;
}

a:hover, a:active {
  color: #33A6BB;
}

h1, h2, h3, h4, h5, h6 {
  color: #33A6BB;
}

ul {
  list-style: none;
  -webkit-padding-start: 0;
}

p {
  margin: 0 0 20px;
}

input[type="text"], input[type="email"], input[type="search"], input[type="password"], input[type="tel"], input[type="url"], input[type="date"], textarea {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  margin: 0 0 20px;
  background-color: #f9f9f9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}

input[type="reset"], input[type="button"], input[type="submit"] {
  background-color: #7c3030;
  border: 1px solid #7c3030;
  color: #FFF;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 30px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  height: 40px;
  padding: 0 20px;
  margin-top: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}

main {
  padding: 30px 0 40px;
  background: white;
}

main h2.title {
  font-size: 20px;
  line-height: 23px;
  padding: 10px;
  margin-bottom: 20px;
  font-weight: normal;
  margin-top: 0;
  color: white;
  background-color: #33A6BB;
  letter-spacing: 2px;
  text-transform: uppercase;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}

/* blog posts */
.post {
  padding: 0 20px 20px;
  margin-bottom: 40px;
  border-bottom: 4px double #ededed;
}

.meta {
  padding: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ededed;
  font-size: 13px;
  background-color: #e4f1f3;
}

.meta i {
  color: #7c3030;
}

.author, .date {
  padding: 0 10px 0px 5px;
}

p.title {
  font-size: 30px;
  line-height: 37px;
  margin-bottom: 0;
  padding-bottom: 10px;
  color: #7c3030;
  border-bottom: 1px solid #ededed;
}

.post-excerpt {
  padding: 0 0 20px;
  margin-bottom: 40px;
  border-bottom: 4px double #ededed;
}

.post-excerpt a.button {
  font-weight: bold;
  text-transform: uppercase;
  color: #33A6BB;
  border-bottom: 1px solid #33A6BB;
  letter-spacing: .1em;
}

.pagination {
  display: block;
  min-height: 50px;
  border-bottom: 1px solid #ededed;
}

.pagination a {
  color: #33A6BB;
  border-bottom: 1px solid #33A6BB;
  letter-spacing: .1em;
}

.pagination a.right {
  display: inline-block;
  float: right;
}

.pagination a.left {
  display: inline-block;
  float: left;
}

.pagination a:hover {
  text-decoration: none;
  font-weight: bold;
}

aside .excerpt {
  margin: 10px auto;
  padding: 10px 0 20px;
  border-bottom: 1px solid #ededed;
}

aside .excerpt p.title {
  border: none;
  font-size: 17px;
  line-height: 20px;
}

aside .excerpt a.button {
  color: #33A6BB;
}

/* sidebar */
aside h3, aside h2.title, aside h3.widget-title {
  font-size: 20px;
  line-height: 23px;
  padding: 10px;
  margin-bottom: 20px;
  font-weight: normal;
  margin-top: 0;
  color: white;
  background-color: #33A6BB;
  letter-spacing: 2px;
  text-transform: uppercase;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}

aside h4 {
  color: #7c3030;
}

/* footer */
footer {
  background-color: #e4f1f3;
  padding: 10px 0 30px;
  color: #33A6BB;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
}

footer .container {
  margin-left: auto;
  max-width: 85%;
}

footer a {
  display: none;
}

footer .copyright {
  text-align: center;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.back-to-top a {
  display: block;
  background-color: #7c3030;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
}

@media screen and (max-width: 830px) {
  footer .copyright {
    padding-bottom: 10px;
    display: block;
  }
}

/*section specific code */
/*styles for page-header partial*/
section.page-title-wrap {
  background-color: #e4f1f3 !important;
  padding: 10px 0 20px;
  text-align: center;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  margin-top: -1px;
}

section.page-title-wrap h1.page-title {
  font-size: 30px;
  line-height: 37px;
  color: #7c3030;
  font-weight: 700;
  margin: 0 auto;
}

/* only needed when using the signup-message partial */
#home-intro {
  height: 340px;
  text-align: center;
  background: white;
}

#home-intro .promotional_bar_content {
  text-align: center;
  height: 340px;
  margin: auto;
  background-image: url(/images/worker-bkg.jpg);
  background-repeat: no-repeat;
  background-position: center top;
}

#home-intro .promotional_bar_content h3 {
  text-transform: uppercase;
  padding: 0 0 10px;
  color: white;
  margin-bottom: 200px;
  background: #7c3030;
  margin-top: 0;
}

#home-intro .promotional_bar_content a {
  background-color: #33A6BB;
  border: 1px solid #33A6BB;
  color: white;
  padding: 10px 20px;
  border-radius: 3px;
  font-size: 18px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
}

/*home search only needed when including the search-jobs partial*/
section#home-search {
  text-align: center;
  border-top: 5px solid #7c3030;
  background: #e4f1f3;
  padding: 20px 0 30px;
}

section#home-search h3 {
  color: #7c3030;
  padding-top: 0;
  font-size: 2em;
  font-weight: 700;
}

section#home-search form {
  max-width: 75%;
  text-align: center;
  margin: auto;
  padding: 0;
}

section#home-search input[type="text"] {
  width: 37%;
}

section#home-search input[type="submit"] {
  background-color: #7c3030;
  border: 1px solid #7c3030;
  color: #FFF;
  display: inline-block;
  height: 45px;
}

@media screen and (max-width: 782px) {
  section#home-search form {
    max-width: 90%;
  }
  section#home-search form input[type="text"] {
    width: 100%;
  }
}

/*by-sector, sectors only needed when including sector-module partial in sidebar
by-location, locations only needed when including city-module partial in sidebar*/
aside .by-sector h3, aside .by-location h3 {
  font-size: 16px;
  line-height: 23px;
  padding-bottom: 15px;
  font-weight: normal;
  margin-top: 0;
  color: #333;
  letter-spacing: 2px;
  text-transform: uppercase;
}

aside ul.sectors li, aside ul.locations li {
  flex-basis: 100%;
  padding-bottom: 5px;
}

aside ul.sectors li a i, aside ul.locations li a i {
  color: #7c3030 !important;
}

/* site specific before_footer*/
.before-footer {
  background-color: #e4f1f3;
  color: #333;
  padding: 30px 0 10px;
  font-size: 12px;
  text-align: center;
  margin: auto;
}

.before-footer img {
  max-width: 120px;
  display: block;
  text-align: center;
  margin: 5px auto 10px;
}

.before-footer a {
  color: #333;
  font-weight: 400;
  font-size: 13px;
  padding: 5px;
}

/*sidebar listings*/
.jobs {
  padding: 0;
}

.jobs h2.Title {
  font-size: 17px;
  max-height: 20px;
  overflow: hidden;
  margin-bottom: 5px;
}

.jobs a.Company {
  color: #33A6BB;
}

.jobs .TimePosted {
  display: block;
  color: #999;
  font-size: 11px;
}

.jobs .snippet {
  display: none;
}
